<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton
                :showBtn="!isTemplateInvoice"
                :showAddNew="false"
                title="Create Pharmacy Bill"
                @onClickCloseButton="goToList"
            >
                <template #extra-btn>
                    <div class="template-selection">
                        <label class="form-label">Select Template</label>
                        <AsyncSelect
                            placeholder="Select Template"
                            v-model="template"
                            class="w-100"
                            :additional-query="{ template_type: 'sale'}"
                            :api-service="fetchTemplates"
                        />
                    </div>
                </template>
            </TitleButton>
        </div>
        <div class="my-2 px-2">
            <TableHeaderData
                :formData="formData"
                :tableItems="tableItems"
                ref="formHeader"
            />
        </div>
        <div class="col-12 px-2">
            <ListTable
                :tableItems="tableItems"
                :discount-account-heads="discountAccountHeads"
                @addNewItem="addNewItem"
                @handleSubmit="handleSubmit"
                :submitLoading="submitLoading"
            />
        </div>
        <GlobalLoader />
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/investigation/CreateIpdBillingTable.vue';
import TableHeaderData from '@/components/molecule/company/hospital/billing-template/TableHeaderInvoiceTemplate.vue';
import {onMounted, ref, inject, provide, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";
import useDate from "@/services/utils/day";
import handlePurchase from "@/services/modules/purchase";
import handleCompany from "@/services/modules/company";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import hospitalPdfPrinterHelper from "@/services/utils/hospitalPdfPrinterHelper";
import handleTemplate from '@/services/modules/all-modules/template'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import Loader from '@/components/atom/LoaderComponent'
import { computed } from 'vue';

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const { fetchSalesBill, ...rest } = handlePurchase();
const { fetchCompanyInfo } = handleCompany();
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const { generatePdf } = hospitalPdfPrinterHelper();
const {currentDate} = useDate();
const { fetchTemplates } = handleTemplate();

const itemSkeleton = {
    name: '',
    description: '',
    quantity: null,
    rate: 0,
    discount_percent: 0,
    vat: 0,
    sub_total: 0,
    discount_amount: 0,
    vat_amount: 0,
    total: 0,
    amount: 0,
    total_amount: 0,
    is_edit: false,
};

const company = ref({});
const locations = ref([]);
const tableItems = ref([]);
const discountAccountHeads = ref([]);
const submitLoading = ref(false);
const invoiceRes = ref(null);
const template = ref(null);
const loader = ref(false);
const formHeader = ref(null);

const isTemplateInvoice = computed(() => $route.name === 'invoice-pharmacy-template' );

const companyId = $route.params.companyId;

const getInitialForm = () => {
    return {
        company_id: companyId,
        contact_profile_id: null,
        account_head_id: null,
        ipd_register_id: null,
        vat_payable_account_head_id: null,
        project_id: null,
        cost_centre_id: null,
        location_id: null,
        mop_account_head_id: null,
        discount_head_id: null,
        service_resource_id: null,
        receipt_reference: null,
        adjustment_amount: null,
        receipt_description: "Pharmacy bill payment",
        payment: 0,
        status: "active",
        date: currentDate(),
        has_item_detail: true,
        description: "",
        item_details: [],
        sale_type: "pharmacy_invoice",
        receipt_type: "pharmacy_invoice"
    }
}

const formData = ref(getInitialForm());

provide('formData', formData);

const addNewItem = () => {
    tableItems.value.push({ ...itemSkeleton });
}

const goToList = () => {
    $router.push({
        name: 'pharmacy-billing',
        params: {
            companyId: $route.params.companyId,
            moduleId: $route.params.moduleId,
            menuId: $route.params.menuId,
            pageId: $route.params.pageId
        },
        query: $route.query
    })
}

const handleSubmit = async () => {
    const emptyQtyItem = tableItems.value.filter((item) => !item.quantity);
    if (emptyQtyItem && emptyQtyItem.length > 0) {
        showError("Item quantity cannot be empty");
        return;
    }

    submitLoading.value = true;
    if (!formData.value.date) formData.value.date = currentDate();
    formData.value.item_details = tableItems.value;

    let copyFormData = Object.assign({}, formData.value);
    copyFormData.item_details = formData.value.item_details
    copyFormData.doctor_order_id = $route.params.orderId
    copyFormData.sale_type = "pharmacy_invoice"
    copyFormData.receipt_type = "pharmacy_invoice"

    await rest.storeBillPayment(copyFormData)
        .then(res => {
            if (res.status) {
                showSuccess(res.message)
                goToPrint(res.data.id)
                isTemplateInvoice.value ? resetForm() : goToList();
                return;
            }
            showError(res.message)
        })
        .catch(err => {
            showError(err.response.data.message)
        })
}

const resetForm = () => {
    tableItems.value = [];
    template.value = null;
    formData.value = getInitialForm();
    if(formHeader.value) formHeader.value.resetData();
}

const goToPrint = (id) => {
    const companyQuery = `?company_id=${companyId}`;

    fetchSalesBill(id, companyQuery).then( (res) =>{
        if( res ){
            invoiceRes.value = res.data;
        }
    }).then( () => {
        generatePdf(company.value, invoiceRes.value, 'BILL', true, true)
    }).catch( (err) => {
        console.log(err);
    })
}

const setTableItems = (data) => {
    
    if(!data.general || data.general.length < 1) return;

    tableItems.value = data.general.map((item) => {
        return {
            ...itemSkeleton,
            product_id: item.product_id,
            name: item.product ? item.product.name : '',
            quantity: item.quantity || 0,
            rate: item.product && item.product.description ? item.product.description.sales_price : 0,
            discount_percent: item.discount_percent || 0,
            discount_amount: item.discount_amount || 0,
            vat: item.vat || 0,
            vat_amount: item.vat_amount || 0,
        }
    })
}

const getSingleSalesBill = async () => {

    const id = template.value && template.value.templateable_id;
    if(!id) return;

    const companyQuery = `?company_id=${companyId}`;
    loader.value = true;

    await fetchSalesBill(id, companyQuery).then( (res) =>{
        if(! res.data) return;
        setTableItems(res.data);
    }).catch( (err) => {
        console.log(err);
    }).finally(() => {
        loader.value = false;
    })
}

watch(template, (newValue) => {
    if(newValue) {
        getSingleSalesBill();
    }
});

onMounted(() => {
    const companyQuery = `?company_id=${companyId}`;
    const locationQuery = `${companyQuery}&location_type=fg_warehouse`;

    $store.commit('setLoading', true);

    Promise.all([
        rest.fetchAccountReceivable(companyQuery).then(res => {
            if (res.data) formData.value.account_head_id = res.data[0].id;
        }),
        rest.fetchVatPayable(companyQuery).then(res => {
            if (res.data) formData.value.vat_payable_account_head_id = res.data[0].id;
        }),
        fetchCompanyInfo(companyId).then(res => {
            if (res.data) company.value = res.data;
        }),
        rest.getAccountHeadBySlag("discount_account", companyQuery).then(res => {
            if (res.data) {
                discountAccountHeads.value = res.data;
                formData.value.discount_head_id = res.data.length ? res.data[0].id : null;
            }
        }),
        fetchBusinessLocationsList(locationQuery).then(res=> {
            if(res.data) {
                locations.value = res.data
                formData.value.location_id = res.data[0].id
            }
        })
    ])
    .finally(() => $store.commit('setLoading', false))
})
</script>

<style scoped>
address {
  margin-bottom: 0 !important;
}
.template-selection {
    width: 400px;
}
</style>